import algoliasearch from "algoliasearch"

class AlgoliaClient {
  constructor({
    algoliaAppId,
    algoliaApiKey,
    searchIndexNames,
    searchOptions,
  }) {
    this.client = algoliasearch(algoliaAppId, algoliaApiKey)
    this.searchIndices = {}
    this.currentIndexName = ""
    this.searchOptions = searchOptions

    searchIndexNames.forEach(({ algoliaName, displayName, isDefault }) => {
      if (isDefault) {
        this.currentIndexName = displayName
      }
      this.searchIndices[displayName] = this.client.initIndex(algoliaName)
    })
  }

  get searchIndex() {
    return this.searchIndices[this.currentIndexName]
  }

  set searchIndex(displayName) {
    this.currentIndexName = displayName
  }

  search({ facetFilters = "", filters = "", query = "" }) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    return self.searchIndex.search(query, {
      ...self.searchOptions,
      filters,
      facetFilters,
    })
  }
}

export default AlgoliaClient
