<template>
  <fieldset class="filter-row">
    <legend v-if="anyFilters">Applied filters:</legend>
    <button
      v-for="(filterValue, displayName) in selectedFilters"
      :key="filterValue"
      class="filter-row-button"
      @click="unselectFilter(filterValue, displayName)"
    >
      <span class="at-text"
        >{{ displayName }}. Click to remove this filter.
      </span>
      {{ displayName }}
      <SvgIcon icon="x" class="filter-row-button-icon" />
    </button>
    <button
      v-if="anyFilters"
      class="filter-row-button filter-row-button-clear"
      @click="$emit('clear-all')"
    >
      Clear All Filters
    </button>
  </fieldset>
</template>

<script>
  export default {
    props: {
      selectedFilters: {
        type: Object,
        required: true,
      },
    },
    computed: {
      anyFilters() {
        return Object.keys(this.selectedFilters).length > 0
      },
    },
    methods: {
      unselectFilter(filterValue, displayName) {
        this.$emit("toggle-filter", { displayName, filterValue })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .filter-row {
    display: flex;
    align-items: center;
    grid-gap: $base-spacing;
    flex-flow: row wrap;
    margin-bottom: $space-m;
    border: none;
    margin: 0;
    padding: 0;
    min-height: $base-spacing * 14;
    legend {
      color: $gray-text;
      margin: 0;
      padding: 0;
      font-weight: bold;
    }
    @include viewport("mini") {
      display: none;
    }
  }

  .filter-row-button {
    display: flex;
    white-space: nowrap;
    grid-gap: $base-spacing;
    align-items: center;
    padding: 1ex 1em;
    font-weight: bold;
    background: $navy-tint;
    color: $navy-text;
    border: none;
    border-radius: $border-radius;
    &:hover {
      color: $navy-tint;
      background: $navy-text;
    }

    &-clear {
      background: $red-tint;
      color: $red-text;
      &:hover {
        color: $red-tint;
        background: $red-text;
      }
    }
  }

  .filter-row-button-icon {
    width: $base-spacing * 3;
    height: $base-spacing * 3;
  }

  .at-text {
    @extend %at-text;
  }
</style>
