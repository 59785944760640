<template>
  <AllTheOils v-if="current1ozSale" :h-padding="true" />
  <Container>
    <FilterRow
      v-if="Object.values(selectedFilters).length > 0"
      :selected-filters="selectedFilters"
      @clear-all="clearAll"
      @toggle-filter="toggleFilter"
    />

    <div class="fragrance-finder">
      <div class="fragrance-finder-head">
        <Button
          layout="inverted"
          size="small"
          color="navy"
          class="mini-only"
          @click="toggleMenu"
        >
          Show Filter
        </Button>
        <span class="fragrance-finder-results">
          {{ resultsCountText }}
        </span>
      </div>
      <label class="fragrance-finder-info-select_wrap">
        <span class="fragrance-finder-info-select_wrap-label"> Sort by: </span>
        <Select
          class="fragrance-finder-info-select"
          :model-value="searchClient.currentIndexName"
          @update:model-value="changeSearchIndex"
        >
          <option
            v-for="option in searchIndicesForSelect"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </Select>
      </label>
      <div
        :class="[
          'fragrance-finder-sidebar',
          mobileMenuOpen && 'fragrance-finder-sidebar-open',
        ]"
      >
        <div class="fragrance-finder-sidebar-head">
          <h2>Filter</h2>
          <Button size="small" color="gray" @click="toggleMenu">Close</Button>
        </div>
        <FacetSidebar
          :loading="loading && !anyResults"
          :facet-groups="facetGroups"
          :selected-filters="selectedFilters"
          @toggle-filter="toggleFilter"
        />
        <div class="fragrance-finder-sidebar-buttons">
          <Button
            size="small"
            :rounded-corners="false"
            :disabled="Object.keys(selectedFilters).length === 0"
            @click="clearAll"
          >
            Reset All Filters
          </Button>
          <Button
            color="gray"
            size="small"
            :rounded-corners="false"
            class="mini-only"
            @click="toggleMenu"
          >
            Done
          </Button>
        </div>
      </div>

      <div class="fragrance-oil-finder-grid">
        <template v-if="loading && !anyResults">
          <div
            v-for="skeleton in [...Array(30)]"
            :key="skeleton"
            class="skeleton"
          />
        </template>
        <template v-else>
          <ProductThumb
            v-for="fragrance in fragrances"
            :id="fragrance.objectID"
            :key="fragrance.objectID"
            :src="fragrance.image"
            :srcset="fragrance.image_src_set"
            :price-range="[fragrance.min_price, fragrance.max_price]"
            :href="urlFor(fragrance)"
            :clean-scent-level="fragrance.clean_scent_level"
            :is-new="fragrance.new_product"
            :name="fragrance.name"
            :rating="Math.round(fragrance.rating)"
            :is-clean-scent="fragrance.clean_scent"
            :is-blending-element="fragrance.blending_elements"
            sizes="175px"
          />
        </template>
      </div>
    </div>
  </Container>
</template>

<script>
  import AlgoliaClient from "./algoliaClient"
  import searchOptions from "./algoliaConfig"
  import FacetParser from "./facetParser"
  import FacetSidebar from "./facetSidebar"
  import FilterRow from "./filterRow"
  import ProductThumb from "~/components/ProductThumb"
  import Container from "~/components/Container"
  import Select from "~/components/Select"
  import Button from "~/components/Button"

  export default {
    components: {
      Select,
      Button,
      Container,
      FacetSidebar,
      FilterRow,
      ProductThumb,
    },
    beforeRouteUpdate() {
      this.search()
    },
    props: {
      searchIndexNames: {
        type: Array,
        required: true,
      },
    },
    setup() {
      const saleStore = useSaleStore()
      const sale = computed(() => saleStore.activeSale)

      return {
        current1ozSale: sale,
      }
    },

    data() {
      return {
        loading: false,
        mobileMenuOpen: false,
        searchClient: new AlgoliaClient({
          algoliaAppId: this.$config.public.ALGOLIA_APPLICATION_ID,
          algoliaApiKey: this.$config.public.ALGOLIA_API_KEY,
          searchIndexNames: this.searchIndexNames,
          searchOptions,
        }),
        facetParser: new FacetParser(),
        fragrances: [],
        selectedFilters: {},
        facetGroups: [],
      }
    },
    computed: {
      resultsCountText() {
        const count = this.fragrances.length
        if (count === 1) {
          return "1 fragrance"
        }
        return `${count} fragrances`
      },
      searchIndices() {
        return Object.keys(this.searchClient.searchIndices)
      },
      searchIndicesForSelect() {
        const result = []
        this.searchIndices.forEach((si) => {
          result.push({
            label: si,
            value: si,
          })
        })
        return result
      },
      anyResults() {
        return this.fragrances.length > 0
      },
    },
    watch: {
      selectedFilters() {
        this.search()
      },
      searchIndices() {
        this.search()
      },
      mobileMenuOpen() {
        this.freezePage()
      },
    },
    created() {
      this.getParamsFromUrl()
      this.search()
    },
    activated() {
      this.getParamsFromUrl()
      this.search()
    },

    methods: {
      getParamsFromUrl() {
        const PARAMETER_IGNORELIST = [
          "_kx",
          "utm_medium",
          "utm_source",
          "utm_term",
          "utm_campaign",
          "utm_content",
        ]

        const PARAMETERS = this.$route.query

        PARAMETER_IGNORELIST.forEach((para) => {
          delete PARAMETERS[para]
        })

        if (PARAMETERS.order) {
          this.searchClient.searchIndex = PARAMETERS.order
          delete PARAMETERS.order
        }

        if (Object.keys(PARAMETERS).length === 0) {
          this.selectedFilters = {}
        } else {
          this.selectedFilters = PARAMETERS
        }
      },
      freezePage() {
        if (this.$mq.mini.value) {
          const html = document.querySelector("body")
          const noScrollClass = "fragrance-finder-no-scroll"
          this.mobileMenuOpen
            ? html.classList.add(noScrollClass)
            : html.classList.remove(noScrollClass)
        }
      },
      search() {
        if (!process.client) {
          return
        }
        this.loading = true
        this.searchClient
          .search({
            filters: Object.values(this.selectedFilters).join(" AND "),
          })
          .then(
            (content) => {
              this.fragrances = content.hits
              this.noteData = content.facets.searchable_notes
              this.facetGroups = this.facetParser.parse(content.facets)
            },
            (error) => {
              console.error(error)
            },
          )
          .finally(() => {
            this.loading = false
          })
      },

      toggleMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen
      },
      changeSearchIndex(value) {
        this.searchClient.searchIndex = value
        this.updateRoutes()
        this.search()
      },
      clearAll() {
        this.selectedFilters = {}
        this.updateRoutes()
      },
      toggleFilter(event) {
        if (this.selectedFilters[event.displayName]) {
          const replacement = {
            ...this.selectedFilters,
          }
          delete replacement[event.displayName]
          this.selectedFilters = replacement
        } else {
          this.selectedFilters = {
            [event.displayName]: event.filterValue,
            ...this.selectedFilters,
          }
        }
        this.updateRoutes()
      },
      updateRoutes() {
        this.$router.push({
          query: {
            order: this.searchClient.currentIndexName,
            ...this.selectedFilters,
          },
        })
      },
      urlFor(fragrance) {
        return fragrance.url_path || `/${fragrance.custom_url}/`
      },
    },
  }
</script>

<style lang="scss">
  .fragrance-finder-no-scroll {
    @media (max-width: 660px) {
      max-height: 100vh;
      overflow: hidden;
    }
  }
</style>
<style lang="scss" scoped>
  @keyframes load {
    50% {
      opacity: 0.7;
    }
  }
  .fragrance-finder {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: $space-m;
    @include viewport("sm") {
      grid-template-columns: 200px 1fr;
    }
    @include viewport("mini") {
      display: flex;
      flex-direction: column;
    }
  }
  .fragrance-finder-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .fragrance-oil-finder-grid {
    display: grid;
    grid-gap: 16px;
    align-content: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
    @include viewport("mini") {
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    }
  }
  // Siderbar
  .fragrance-finder-sidebar {
    transition:
      transform 180ms ease-in-out,
      opacity 180ms ease-in-out;

    @include viewport("mini") {
      box-sizing: border-box;
      z-index: 100;
      position: fixed;
      left: 0;
      top: 0;
      background-color: white;
      padding: $space-s;
      height: 100vh;
      overflow-y: scroll;
      transform: translateX(-100vw);
      opacity: 0;
    }

    &-open {
      @include viewport("mini") {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
  .fragrance-finder-sidebar-head {
    display: none;
    align-items: center;
    justify-content: space-between;
    @include viewport("mini") {
      display: flex;
    }
  }
  // Fragrance Info

  .fragrance-finder-results {
    display: inline-block;
    font-weight: bold;
    padding: $space-s 0;
  }

  .fragrance-finder-info-select_wrap {
    display: flex;
    align-items: center;
    flex-grow: 0;
    justify-self: flex-end;
    grid-gap: $base-spacing * 2;
    &-label {
      font-weight: bold;
      white-space: nowrap;
    }
    @include viewport("mini") {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .fragrance-finder-info-select {
    max-width: 200px;
    @include viewport("mini") {
      max-width: none;
    }
  }

  .fragrance-finder-sidebar-buttons {
    display: flex;
    padding: $space-s 0;
    grid-gap: $space-s;
  }

  .skeleton {
    aspect-ratio: 0.68;
    background: $gray-200;
    border-radius: $border-radius;
    animation: load 1.5s ease-in-out infinite;
  }

  .mini-only {
    display: none;
    @include viewport("mini") {
      display: flex;
    }
  }
</style>
